<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingBusiness">
              <ValidationObserver
                v-if="business"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateBusiness)"
                >
                  <div class="row">
                    <div class="col-lg-4">
                      <TextInput
                          v-model="business.business_name"
                          name="business_name"
                          rules="required"
                          label="Business Name"
                      />
                    </div>
                    <div class="col-lg-5">
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.address"
                              name="business_address"
                              rules="required"
                              label="Address"
                          />
                        </div>
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.city"
                              name="business_city"
                              rules="required"
                              label="City"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3">
                      <div class="row">
                        <div class="col-md-5">
                          <TextInput
                              v-model="business.state"
                              name="business_state"
                              rules="required"
                              label="State"
                          />
                        </div>
                        <div class="col-md-7">
                          <TextInput
                              v-model="business.zip"
                              name="business_zip"
                              rules="required"
                              label="Zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row">
                        <div class="col-md-6">
                          <TextInput
                              v-model="business.country"
                              name="country"
                              label="Country"
                          />
                        </div>
                        <div class="col-md-6 mb-sm-4">
                          <label>Business Phone</label>
                          <VuePhoneNumberInput v-model="business.business_phone" valid-color="#e2e7f1" />
                          <!--                          <TextInput
                                                        v-model="business.business_phone"
                                                        v-mask="'(###) ###-####'"
                                                        name="business_phone"
                                                        rules="required|phone_number"
                                                        label="Business Phone"
                                                    />-->
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.origin"
                                name="origin"
                                label="Originating URL"
                            />
                          </div>
                          <div class="col-md-6">
                            <TextInput
                                v-model="business.business_website"
                                name="business_website"
                                label="Website URL"
                            ><span v-if="business.business_website"><a :href="business.business_website" target="_blank" class="btn btn-dark-blue link-target align-middle"><Feather class="link-eye" type="eye" /></a></span></TextInput>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="row btn-links">
                        <div v-if="business.place_id" class="col-md-6 mb-sm-3">
                          <label class="d-block">Maps Link</label>
                          <a class="btn btn-dark-blue form-control" target="_blank" :href="business.google_maps_url"><span class="d-inline-block align-top">View Business On Maps</span> <Feather class="ml-2 hidden-sm hidden-xs" type="eye" /></a>
                        </div>
                        <div class="col-sm-6">
                          <TextInput
                              v-model="business.date"
                              name="date"
                              label="Date Created"
                              disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                        v-model="business.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                        v-model="business.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <label>Phone Number</label>
                      <VuePhoneNumberInput v-model="business.phone_number" :error="phoneError" valid-color="#e2e7f1" rules="required" />
                    </div>
                    <div class="col-md-6 col-lg-3">
                      <TextInput
                          v-model="business.email"
                          name="owner.email"
                          rules="required|email"
                          label="Email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div v-if="business.user_id" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.active">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">Business Status</span>
                          <b-form-checkbox
                              v-model="business.active"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div v-if="user.has_re" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.is_reply_engine">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">ReplyEngine</span>
                          <b-form-checkbox
                              v-model="business.is_reply_engine"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div v-if="user.has_te" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.is_tap_engine">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">TapEngine</span>
                          <b-form-checkbox
                              v-model="business.is_tap_engine"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div v-if="user.has_social_review" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.is_social_review">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">SocialReviews</span>
                          <b-form-checkbox
                              v-model="business.is_social_review"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div v-if="user.has_social_fusion" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.is_social_fusion">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">SocialFusion</span>
                          <b-form-checkbox
                              v-model="business.is_social_fusion"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div v-if="user.has_review_requester" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.is_text_requester">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">ReviewSurge</span>
                          <b-form-checkbox
                              v-model="business.is_text_requester"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                      <div v-if="user.has_insta_stream" class="d-inline-block mr-3 mb-3">
                        <small class="mb-1 font-weight-bold d-block text-right" style="padding-right: 25px">
                          <span v-if="business.is_insta_stream">Active</span>
                          <span v-else>Disabled</span>
                        </small>
                        <div class="d-inline-flex">
                          <span class="mr-2 d-flex">InstaStream</span>
                          <b-form-checkbox
                              v-model="business.is_insta_stream"
                              size="sm"
                              name="initial-button"
                              switch
                              inline
                          >
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <button class="btn btn-success border-0 mr-4 btn-create-widget" @click="updatingOptIn = true">
                      <b-spinner v-if="loadingOptIn" small />
                      <span v-else>
                        <span v-if="!business.user_id">
                          Create Customer & Send Google Connect Info
                        </span>
                        <span v-else>
                          Resend Google Connect Info
                        </span>
                      </span>
                    </button>
                    <span v-if="(invalid && touched)"></span>
                    <b-button
                        variant="primary"
                        class="float-right"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                    >
                      <b-spinner v-if="loadingUpdate" small />
                      <span v-else>Save</span>
                    </b-button>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  components:{
    VuePhoneNumberInput
  },
  data() {
    return {
      countries: [{ key: 'US', label: 'United States of America' }, { key: 'CA', label: 'Canada' }],
      loadingBusiness: false,
      business: null,
      loadingUpdate: false,
      loadingPassword: false,
      loadingOptIn:false,
      updatingOptIn:false,
      phoneError:false,
      password: {
        password: '',
        password_confirmation: '',
      },
      open:false,
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    currencies() {
      return this.$store.getters['app/currencies']
    },

    dateFormats() {
      return this.$store.getters['app/dateFormats']
    },

    onlyCountries() {
      return [ this.business.country ]
    },
    user() {
      return this.$store.getters['auth/user']
    },
  },
  mounted() {
    this.$nextTick();
    this.getBusiness();
  },

  methods: {
    optInLead(){
      this.loadingOptIn = true;
      this.$store
          .dispatch('business/optIn', this.business)
          .then(() => {
            this.loadingOptIn = false
            this.updatingOptIn = false;
          })
          .catch((err) => {
            this.loadingOptIn = false
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.updatingOptIn = false;
          })
    },
    updateBusiness() {
      if(this.updatingOptIn){
        return this.optInLead();
      }
      this.loadingUpdate = true
      this.phoneError = false;
      console.log(this.business)
      this.$store
        .dispatch('business/update', this.business)
        .then((res) => {
          this.loadingUpdate = false
          console.log(res)
        })
        .catch((err) => {
          this.loadingUpdate = false
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
            if(Object.keys(err.response.data.errors).includes('phone_number')){
              this.phoneError = true;
            }
          }
        })
    },

    getBusiness() {
      this.loadingBusiness = true
      this.$store
        .dispatch('business/find', this.$route.params.id)
        .then((business) => {
          this.loadingBusiness = false;
          this.$nextTick();
          business.message_limit = business.no_messages != null
          business.contact_limit = business.no_contacts != null
          this.business = business
          const rwgReviews = {
              current_page:1,
              data: business.reviews,
              next_page_url:null
          };
          sessionStorage.setItem('rwg_' + business.place_id,JSON.stringify({
            place_id:business.place_id,
            business_name:business.business_name,
            rating:5,
            total_review:1,
            reviews:rwgReviews
          }));
          setTimeout(function(){
            document.documentElement.dispatchEvent(new CustomEvent('setupRWG'));
          }, 100);
        })
        .catch(() => {
          this.$router.push({ name: 'agency.businesses.index' })
          this.loadingBusiness = false
        }).finally(() => {
      })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.business.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
  @import url('https://unpkg.com/vue-tel-input@5.12.0/dist/vue-tel-input.css');
  i.feather--eye{
    margin-top: -3px;
  }
  .btn-links .btn{
    white-space: nowrap;
   span{
      vertical-align: top;
    }
  }
  input[name="unique_id"] {
    max-width: 150px !important;
  }
  .copy-link, .link-target{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .link-target{
    max-height: 39px;
  }
  .business-reviews{
    border-top: 2px solid #ccc;
    padding-top: 20px;
  }
</style>
<style lang="scss">
.business-reviews{
  .rwg-app-example-slider{
    .rwg-title{
      display: none;
    }
    .rwg-review-header{
      display: none;
    }
  }
}
#MazPhoneNumberInput {
  label.input-tel__label, label.country-selector__label {
    color: #4b4b5a !important;
    font-weight: normal;
  }
}
</style>
